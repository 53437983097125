<template>
	<div class="lecturer_page">
		<Banner :adCategory="4"></Banner>
		<div class="module1">
			<div class="content">
				<CourseTitle
					title="师资优势"
					contText="拥有建筑行业各细分领域的权威师资专家"
				>
				</CourseTitle>
				<div class="advantage_box flex">
					<div
						class="list flex-center column"
						:class="advantageIndex == index ? 'listActive' : ''"
						v-for="(item, index) in formData.teacherAdvantageVOS"
						:key="index"
						@mouseover="mouseOver(index)"
						@mouseleave="mouseLeave"
					>
						<img
							class="icon_img"
							:src="advantageIndex == index ? item.highlightIcon : item.icon"
							alt=""
						/>
						<div class="experience">{{ item.advantageName }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="module2">
			<div class="content">
				<div class="content_Title display column">
					<div class="title">名师团队</div>
					<div class="cont_text">金牌讲师实战教学， 让你快速掌握岗位技能</div>
					<div class="cont_text">
						果尔佳职业技能培训学校名师团队由国内资深专家、学者、讲师、从业20年以上的施工师傅组成。果尔佳始终重视师资队伍的建设，通过不断优化教学团队，为品质教学提供核心保障
					</div>
				</div>
				<div class="swiper_box">
					<swiper class="swiper" ref="swiper" :options="swiperOption">
						<swiper-slide
							v-for="(item, index) in formData.teacherVOS"
							:key="index"
						>
							<div
								class="list"
								@mouseover="lecturerMouseOver(index)"
								@mouseleave="lecturerMouseLeave"
							>
								<img class="swiper_img" :src="item.lecturerAvatar" alt="" />

								<div class="introduction_box" v-if="lecturerIndex == index">
									<div class="lecturer_name">{{ item.lecturerName }}</div>
									<div class="line"></div>
									<div class="instructor_experience" v-html="item.honor"></div>
								</div>
								<div class="cont" v-else>
									<div class="name">{{ item.lecturerName }}</div>
									<div class="workingExperience row">
										{{ item.introduction }}
									</div>
								</div>
							</div>
						</swiper-slide>

						<div class="swiper-button-prev" slot="button-prev">
							<img
								class="icon_left_right"
								src="@/assets/img/home/left1.png"
								alt=""
							/>
						</div>
						<div class="swiper-button-next" slot="button-next">
							<img
								class="icon_left_right"
								src="@/assets/img/home/right1.png"
								alt=""
							/>
						</div>
					</swiper>
				</div>
			</div>
		</div>
		<div class="module3">
			<div class="content">
				<div class="content_Title display column">
					<div class="title">学员评价</div>
					<div class="cont_evaluate">老师教得好不好，学员都这么说</div>
					<div class="cont_text">毫无保留传授技术，随时解答学员遇到的问题</div>
				</div>
				<div class="student_box sidebar flex-center column">
					<Scroll
						:data="formData.teacherStudentEvaluationVOS"
						:class-option="bbsOption"
					>
						<div
							class="student_list flex-center"
							v-for="(item, index) in formData.teacherStudentEvaluationVOS"
							:key="index"
						>
							<div class="studentImg display column">
								<img :src="item.traineesAvatar" alt="" />
								<div class="name">{{ item.traineesName }}</div>
							</div>
							<div class="student_evaluate flex">
								<img
									class="icon_fl"
									src="@/assets/img/lecturer/fl.png"
									alt=""
								/>

								<p class="evaluate">
									{{ item.commentContent }}
								</p>
							</div>
						</div>
					</Scroll>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import CourseTitle from './components/courseTitle.vue'
import Banner from '@/components/banner'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import Scroll from 'vue-seamless-scroll'
export default {
	components: {
		Banner,
		CourseTitle,
		Swiper,
		SwiperSlide,
		Scroll,
	},
	data() {
		return {
			advantageIndex: null,
			lecturerIndex: null,
			advantageList: [
				{
					id: 1,
					name: '专业经验40年以上',
					url: require('@/assets/img/lecturer/s1.png'),
					urls: require('@/assets/img/lecturer/s2.png'),
				},
				{
					id: 2,
					name: '平均授课20年以上',
					url: require('@/assets/img/lecturer/s3.png'),
					urls: require('@/assets/img/lecturer/s4.png'),
				},
				{
					id: 3,
					name: '课程设计实践经验10年以上',
					url: require('@/assets/img/lecturer/s5.png'),
					urls: require('@/assets/img/lecturer/s6.png'),
				},
			],

			lecturerList: [],
			evaluateList: [],
			swiperOption: {
				slidesPerView: 4,
				spaceBetween: 26,
				direction: 'horizontal',
				observer: true,
				observeParents: true,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				on: {
					resize: () => {
						this.$refs.swiper.$swiper.changeDirection(
							window.innerWidth <= 400 ? 'vertical' : 'horizontal'
						)
					},
				},
			},
			formData: {},
		}
	},
	mounted() {
		this.getData()
		document.documentElement.scrollTop = 0
	},
	computed: {
		bbsOption() {
			return {
				step: 0.8, // 数值越大速度滚动越快
				limitMoveNum: 3, // 开始无缝滚动的数据量 this.bbsData.length
				hoverStop: true, // 是否开启鼠标悬停stop
				direction: 1, // 0向下 1向上 2向左 3向右
				openWatch: true, // 开启数据实时监控刷新dom
				singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
				singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
				waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
			}
		},
	},
	methods: {
		getData() {
			this.api.findTeacherData().then((res) => {
				this.formData = res.data
			})
		},
		// 师资优势移入
		mouseOver(index) {
			this.advantageIndex = index
		},
		// 师资优势移出
		mouseLeave() {
			this.advantageIndex = null
		},
		// 讲师详情移入
		lecturerMouseOver(index) {
			this.lecturerIndex = index
		},
		// 讲师详情移出
		lecturerMouseLeave() {
			this.lecturerIndex = null
		},
	},
}
</script>
<style lang="scss" scoped>
.lecturer_page {
	.banner {
		width: 100%;
		img {
			height: 540px;
		}
	}
	.module1 {
		height: 567px;
		.advantage_box {
			margin-top: 50px;
			.listActive {
				background: #003c7e !important;
				.experience {
					color: white !important;
				}
			}
			.list:nth-child(1) {
				.icon_img {
					width: 90px;
					height: 92px;
				}

				.experience {
					margin-top: 32px;
				}
			}

			.list:nth-child(2) {
				.icon_img {
					width: 100px;
					height: 100px;
				}

				.experience {
					margin-top: 32px;
				}
			}

			.list:nth-child(3) {
				margin-right: 0px !important;
				.icon_img {
					width: 118px;
					height: 88px;
				}

				.experience {
					margin-top: 40px;
				}
			}

			.list {
				width: 380px;
				height: 248px;
				background: #f8f8f8;
				border-radius: 3px;
				margin-right: 35px;

				.icon_img {
					margin-top: 50px;
				}

				.experience {
					font-size: 24px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;
				}
			}
		}
	}
	.module2 {
		height: 835px;
		background: url('../../assets/img/lecturer/bg1.png') no-repeat;
		background-size: 100% 100%;
		.content_Title {
			padding-top: 98px;

			.title {
				font-size: 36px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #ffffff;
				line-height: 24px;
			}

			.cont_text {
				width: 700px;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #ffffff;
				line-height: 24px;
				text-align: center;
				margin-top: 21px;
			}
		}
		.swiper_box {
			margin-top: 63px;
			::v-deep.swiper-container {
				height: 420px;
			}
			.icon_left_right {
				width: 48px;
				height: 48px;
			}
			.list {
				width: 280px;
				height: 420px;
				position: relative;
				.swiper_img {
					width: 100%;
					height: 420px;
				}
				.cont {
					width: 282px;
					height: 88px;
					padding-left: 29px;
					padding-top: 19px;
					box-sizing: border-box;
					background: linear-gradient(-30deg, #004b9d, #0079cd);
					position: absolute;
					left: 0;
					bottom: 0;
					.name {
						font-size: 24px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #ffffff;
						line-height: 24px;
					}
					.workingExperience {
						width: 240px;
						font-size: 16px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #ffffff;
						line-height: 16px;
						margin-top: 12px;
					}
				}
				.introduction_box {
					width: 280px;
					height: 420px;
					background: rgba(255, 255, 255, 0.9);
					position: absolute;
					top: 0;
					left: 0;
					padding: 39px 26px;
					.lecturer_name {
						font-size: 24px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #333333;
						line-height: 24px;
					}
					.line {
						width: 44px;
						height: 2px;
						background: #ff881e;
						margin-top: 14px;
					}
					.instructor_experience {
						height: 280px;
						overflow: hidden;
						overflow-y: scroll;
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						line-height: 24px;
						margin-top: 18px;
					}
					.instructor_experience::-webkit-scrollbar {
						display: none;
					}
				}
			}
		}
	}
	.module3 {
		height: 947px;
		.content_Title {
			padding-top: 98px;
			.title {
				font-size: 36px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #333333;
				line-height: 34px;
			}
			.cont_evaluate {
				font-size: 24px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				line-height: 24px;
				margin-top: 20px;
			}
			.cont_text {
				width: 700px;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #666;
				line-height: 24px;
				text-align: center;
				margin-top: 18px;
			}
		}
		.student_box {
			height: 529px;
			overflow: hidden;
			margin-top: 46px;
			.student_list {
				margin-bottom: 39px;
				.studentImg {
					width: 80px;
					img {
						width: 80px;
						height: 80px;
						border-radius: 50%;
					}
					.name {
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						margin-top: 14px;
					}
				}
				.student_evaluate {
					width: 920px;
					height: 120px;

					margin-left: 52px;
					.icon_fl {
						width: 61px;
						height: 120px;
					}
					.evaluate {
						background: #a9e97a;
						width: 859px;
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						line-height: 24px;
						padding-top: 26px;
						padding-right: 46px;
						box-sizing: border-box;
					}
				}
			}
		}
	}
}
</style>
<style lang="scss">
.swiper-button-prev:after,
.swiper-button-next:after {
	content: none;
}
</style>